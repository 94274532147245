import React from "react";
import logo from './logo.png'
import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'react-bootstrap'
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import Aboutme from "./aboutme";
import Oracle from './oracle';
import { Route, Routes } from "react-router-dom"

function App() {
  return (
    <>
    <div className='Appdiv'>
      <Navbar bg='dark-blue' variant="dark" sticky='top' collapseOnSelect className="navbar">
        <Navbar.Brand>
          <img src={logo} alt='logo' className="logoimg"/>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="navigation_bar navbar-nav ms-auto">
            <Nav.Link href='aboutme'>About Me</Nav.Link>
            <NavDropdown title='Cloud Projects' className="dropdown navbar-right custom-dropdown" >
              <NavDropdown.Item href='oracle' className="dropdown-item">Oracle</NavDropdown.Item>
              <NavDropdown.Item href='google' className="dropdown-item">Google</NavDropdown.Item>
              <NavDropdown.Item href='azure' className="dropdown-item">Azure</NavDropdown.Item>
              <NavDropdown.Item href='amazon' className="dropdown-item">Amazon</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Networking' className="dropdown navbar-right" aria-labelledby="navbarDarkDropdownMenuLink">
              <NavDropdown.Item href='cisco' className="dropdown-item">Cisco</NavDropdown.Item>
              <NavDropdown.Item href='juniper' className="dropdown-item">Juniper</NavDropdown.Item>  
              <NavDropdown.Item href='checkpoint' className="dropdown-item">Checkpoint</NavDropdown.Item>
              <NavDropdown.Item href='fortinet' className="dropdown-item">Fortinet</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Coding' className="dropdown navbar-right">
              <NavDropdown.Item href='web' className="dropdown-item">Web Apps</NavDropdown.Item>
              <NavDropdown.Item href='scripts' className="dropdown-item">Scripts</NavDropdown.Item>  
              <NavDropdown.Item href='desktop' className="dropdown-item">Desktop Apps</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Nav.Item className="nav_spacer">
        </Nav.Item>
      </Navbar>
    </div>
      <div className="contentdiv">
      <div className='mainbody'>
        <Routes>
          <Route path="/" element={<Aboutme />} />
          <Route path="aboutme" element={<Aboutme />} />
          <Route path="oracle" element={<Oracle />} />
        </Routes>
      </div>
    </div>
    </>
  );
}

export default App;
