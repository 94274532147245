import React from 'react'
import me from './me.jpg'

export default function Aboutme() {
  return (
    <>
      <h1 className='myName'>Arpad Kopenczei</h1>
      <table width='98%' margin='auto' className='addressTable'>
        <tr height='20px'>
          <td>
          </td>
          <td rowSpan='8'>
            <img src={me} alt='me' className="me_img"/>
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
            <h5>Telephone EU: +36 30 930 3032</h5>
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
          <h5>Telephone US: +1 909 418 8562</h5>
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
          <h5>e-mail: arpad@kopenczei.net</h5>
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
          <h5>Address: Kisbödő Street 43, Szekszárd 7100 Hungary</h5>
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
          <h5>Birthday: 14 November 1985</h5>
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
          <h5>LinkedIn: <a href="https://www.linkedin.com/in/%C3%A1rp%C3%A1d-k%C3%B6penczei-8a1a2196/" target="_blank">https://www.linkedin.com/in/árpád-köpenczei-8a1a2196/</a></h5>
          </td>
        </tr>
      </table>
      <table width='98%' margin='auto' className='cv_table'>
        <tr height='20px'>
          <td colSpan='3'></td>
        </tr>
        <tr>
          <td colSpan='3' className='section_cells'><h1 className='section_title'>About Me</h1></td>
        </tr>
        <tr>
          <td colSpan='3' className='indented_td'>
            I am an information technology professional with more then 10 years experience in the field. I pride myself on my customer service, troubleshooting skills and ability to quickly learn new technologies. 
            I specialize in networking, network security, active directory and virtualization. For my next challenge, I would like be in a position that would allow me 
            to gain some public cloud and/or automation experience. Besides the experiences listed below, you can find examples of my personal experiences while working on personal projects.
          </td>
        </tr>
        <tr height='20px' colSpan='3'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='3' className='section_cells'><h1 className='section_title'>Education</h1></td>
        </tr>
        <tr>
          <td colSpan='2' className='indented_td'>
            <b>Chaffey Community College, Rancho Cucamonga, CA</b>
          </td>
          <td  className='dates'>
            <b>2003 - 2006</b>
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
            Computer information systems
          </td>
        </tr>
        <tr height='10px' colSpan='3'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='2' className='indented_td'>
            <b>Cathedral City High School, Cathedral City, CA</b>
          </td>
          <td  className='dates'>
            <b>2000 - 2003</b>
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
            High School Diploma - General Education
          </td>
        </tr>
        <tr height='20px' colSpan='3'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='3' className='section_cells'><h1 className='section_title'>Certifications</h1></td>
        </tr>
        <tr>
          <td  className='indented_td'>
            CompTIA A+, Net+, Sec+
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
            Cisco CCNA
          </td>
        </tr>
        <tr>
          <td className='indented_td'>
            VMware VCP
          </td>
        </tr>
        <tr height='20px' colSpan='3'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='3' className='section_cells'><h1 className='section_title'>Work Experience</h1></td>
        </tr>
        <tr>
          <td colSpan='1' className='indented_td'>
            <b>IP Expert</b>
          </td>
          <td colSpan='2' className='dates'>
            <b>July 2020 - Present</b>
          </td>
        </tr>
        <tr>
          <td className='indented_td'  colSpan='3'>
            Magyar Telekom, Budapest, Hungary / Deutsche Telekom, Bonn, Germany
          </td>
        </tr>
        <tr>
          <td width='20%'>
          </td>
          <td className='experience' width='65%'>
            <ul>
              <li>Cisco device management, troubleshooting (Nexus 5k, ASR9k, ASR1K, various Catalyst switches and routers)</li>
              <li>Juniper router administration</li>
              <li>Fortinet Firewall administration, troubleshooting, configuration</li>
              <li>Checkpoint firewalls administration, troubleshooting, upgrades</li>
              <li>Windows server administration:</li>
              <ul>
                <li>WDS, WSUS, GPO, AD</li>
                <li>Windows Server 2012 R2, 2016, 2019</li>
              </ul>
              <li>VMWare installation, troubleshooting and administration</li>
              <li>Handling of IP connections, routing (BGP and OSPF), VPN (IPsec, GVPN)</li>
              <li>POC and deployment of network monitoring software (Zabbix)</li>
              <li>Radware load balancer Deployment, administration</li>
            </ul>
          </td>
          <td>
          </td>
        </tr>
        <tr height='10px' colSpan='3'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='1' className='indented_td'>
            <b>Network Engineer</b>
          </td>
          <td colSpan='2' className='dates'>
            <b>February 2018 - July 2020</b>
          </td>
        </tr>
        <tr>
          <td className='indented_td'  colSpan='3'>
          Agua Caliente Band of Cahuilla Indians, Palm Springs, CA
          </td>
        </tr>
        <tr>
          <td width='20%'>
          </td>
          <td className='experience' width='65%'>
            <ul>
              <li>Cisco catalyst Switches – Deployment, administration and troubleshooting</li>
              <li>Cisco 9k Switch - troubleshooting</li>
              <li>Cisco CUBE VOIP routers – Troubleshooting, OSPF, EIGRP, BGP</li>
              <li>Cisco WLC – WiFi setup and troubleshooting</li>
              <li>Cisco Telephone systems – UCCX programing, CUCM management, troubleshooting</li>
              <li>Cisco UCS – Deployment, Administration, troubleshooting</li>
              <li>Checkpoint firewalls – Administration, troubleshooting</li>
              <li>Windows server administration:</li>
              <ul>
                <li>WDS, WSUS, GPO, AD</li>
                <li>Windows Server 2003, 2008, 2008 R2, 2012, 2012 R2, 2016, 2019</li>
              </ul>
              <li>Kaspersky and Sophos Antivirus – deployment, administration</li>
              <li>RSA – Multifactor authentication deployment, administration and troubleshooting</li>
              <li>CITRIX – Deployment and administration</li>
              <li>VMWare – Installation, administration and troubleshooting</li>
              <li>Netvault – Data Backups and documentation</li>
              <li>DELL Compellent – Administration and troubleshooting</li>
              <li>PRTG – Network Monitoring</li>
              <li>Project Management, infrastructure planning</li>
              <li>PCI Compliance</li>
            </ul>
          </td>
          <td>
          </td>
        </tr>
        <tr height='10px'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='1' className='indented_td'>
            <b>Network Administrator</b>
          </td>
          <td colSpan='2' className='dates'>
            <b>June 2016 – February 2018</b>
          </td>
        </tr>
        <tr>
          <td className='indented_td' colSpan='3'>
            Hollywood Casino Jamul (Penn National Gaming), Jamul, CA
          </td>
        </tr>
        <tr>
          <td width='20%'>
          </td>
          <td className='experience' width='65%'>
            <ul>
              <li>Cisco ASA firewall – administration</li>
              <li>Cisco Catalyst and 7K Switches – Administration and troubleshooting</li>
              <li>Cisco Catalyst Switches - troubleshooting</li>
              <li>CUCM – management and troubleshooting</li>
              <li>VMWare – Administration and troubleshooting</li>
              <li>EMC SAN - administration</li>
              <li>AS400 - troubleshooting</li>
              <li>MICROS Point of Sale – Deployment, administration, programming and troubleshooting</li>
              <li>Cisco WLC – WiFi Troubleshooting</li>
              <li>Windows server administration:</li>
              <ul>
                <li>WDS, WSUS, GPO, AD</li>
                <li>Windows Server 2008 R2, 2012, 2012 R2</li>
              </ul>
              <li>Project Management</li>
              <li>Solar Winds – Deployment, network monitoring</li>
              <li>MS SQL Scripting, reporting</li>
              <li>PCI Compliance reporting</li>
            </ul>
          </td>
          <td>
          </td>
        </tr>
        <tr height='10px' colSpan='3'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='1' className='indented_td'>
            <b>IT Technician</b>
          </td>
          <td colSpan='2' className='dates'>
            <b>June 2014 – June 2016</b>
          </td>
        </tr>
        <tr>
          <td className='indented_td' colSpan='3'>
            Hollywood Casino at Kansas Speedway (Penn National Gaming), Kansas City, KS
          </td>
        </tr>
        <tr>
          <td width='20%'>
          </td>
          <td className='experience' width='65%'>
            <ul>
              <li>Deployment and troubleshooting of workstations and mobile phones</li>
              <li>MICROS Point of sale - Deployment, administration and troubleshooting</li>
              <li>Cisco Catalyst Switches - troubleshooting</li>
              <li>Windows server administration:</li>
              <ul>
                <li>WDS, WSUS, GPO, AD</li>
                <li>Windows Server 2003, 2008, 2008 R2, 2012, 2012 R2</li>
              </ul>
              <li>AS400 - troubleshooting</li>
              <li>VMWare – Installation, administration and troubleshooting</li>
              <li>EMC SAN – Administration</li>
              <li>CUCM – management and troubleshooting</li>
              <li>Comvault - Data Backups and documentation</li>
              <li>Helpdesk</li>
            </ul>
          </td>
          <td>
          </td>
        </tr>
        <tr height='10px' colSpan='3'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='1' className='indented_td'>
            <b>Systems Administrator</b>
          </td>
          <td colSpan='2' className='dates'>
            <b>January 2013 – May 2014</b>
          </td>
        </tr>
        <tr>
          <td className='indented_td' colSpan='3'>
            Agua Caliente Band of Cahuilla Indians, Palm Springs, CA
          </td>
        </tr>
        <tr>
          <td width='20%'>
          </td>
          <td className='experience' width='65%'>
            <ul>
              <li>Setup and deployment of IT workstations and mobile phones</li>
              <li>Helpdesk</li>
              <li>Tier 1 troubleshooting of all reported incidents</li>
            </ul>
          </td>
          <td>
          </td>
        </tr>
        <tr height='10px' colSpan='3'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='1' className='indented_td'>
            <b>Independent Consultant</b>
          </td>
          <td colSpan='2' className='dates'>
            <b>June 2003 – December 2012</b>
          </td>
        </tr>
        <tr>
          <td className='indented_td' colSpan='3'>
            Ontario, CA/Palm Springs, CA
          </td>
        </tr>
        <tr>
          <td width='20%'>
          </td>
          <td className='experience' width='65%'>
            <ul>
              <li>Client computer setup and deployment</li>
              <li>SOHO network setup</li>
              <li>Virus removal</li>
              <li>Data Backup</li>
              <li>Computer tutoring</li>
              <li>Web design</li>
            </ul>
          </td>
          <td>
          </td>
        </tr>
        <tr height='10px' colSpan='3'>
          <td >
          </td>
        </tr>
        <tr>
          <td colSpan='3' className='section_cells'><h1 className='section_title'>Languages</h1></td>
        </tr>
        <tr>
          <td width='20%'>
          </td>
          <td className='experience' width='65%'>
            <ul>
              <li>Hungarian – Advanced Level</li>
              <li>English – Advanced level</li>
              <li>Romanian – Medium level</li>
            </ul>
          </td>
          <td>
          </td>
        </tr>
        </table>
    </>
  )
}